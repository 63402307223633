export default {
  computed: {
    allRoles() {
      const roles = [
        { value: "patient", text: this.$t("roles.patient") },
        { value: "administrator", text: this.$t("roles.administrator") },
        { value: "investigator", text: this.$t("roles.investigator") },
        {
          value: "dataadministrator",
          text: this.$t("roles.dataadministrator")
        },
        { value: "studycoordinator", text: this.$t("roles.studycoordinator") },
        { value: "cst", text: this.$t("roles.cst") },
        { value: "cra", text: this.$t("roles.cra") },
        { value: "datamanager", text: this.$t("roles.datamanager") },
        { value: "deo", text: this.$t("roles.deo") }
      ];
      return this.isUserProfile ? roles : roles.slice(1);
    },
    knownWorkflows() {
      return this.currentStudy.workflows
        .filter(w => w.name != "main")
        .map(w => {
          const [role, specifier] = w.name.split(":");
          const roleText = this.allRoles.find(r => r.value == role)?.text;
          return {
            value: w.name,
            text: `${roleText} (${specifier})`
          };
        });
    },
    allWorkflows() {
      return [...this.knownWorkflows, ...this.allRoles]
        .sort((a, b) => a.text.localeCompare(b.text))
        .filter((v, x, arr) => x == 0 || arr[x - 1].value != v.value);
    },
    isHomePage() {
      return this.currentPageSet == this.currentStudy.mainWorkflow.info;
    }
  }
};
